@import "_variables";
@import "_mixins";

.splide__video__wrapper {
    display: initial !important;
}

.splide__slide {
    background-size: initial !important;
    .video-card-title {
        pointer-events: none;
        position: absolute;
        z-index: 100;
        color: #fff;
        font-size: 41px;
        letter-spacing: -0.7px;
        font-weight: 800;
        background: #310a2b33;
        padding: 10px 20px;
        border-radius: 10px;
        line-height: 47px;
        top: 20px;
        left: 20px;
        right: 20px;
        opacity: 0.9;
        text-shadow: 2px 2px 4px #3b0031c2;

        @include breakpoint-md2(max) {
            font-size: 35px;
            line-height: 40px;
        }
        @include breakpoint-md1(max) {
            font-size: 28px;
            line-height: 30px;
        }
        @include breakpoint-md(max) {
            font-size: 24px;
            line-height: 26px;
        }
        @include breakpoint-sm0(max) {
            font-size: 21px;
            line-height: 23px;
            top: 15px;
            left: 15px;
            right: 15px;
            padding-top: 8px;
        }
        @include breakpoint-sm(max) {
            font-size: 19px;
            line-height: 21px;
            top: 10px;
            left: 10px;
            right: 10px;
            padding-top: 5px;
        }
        @include breakpoint-sm2(max) {
            font-size: 17px;
            line-height: 19px;
            top: 7px;
            left: 0;
            right: 0;
            padding-top: 2px;
        }
    }
    .video-make-btn{
        z-index: 101;
        font-size: 18px !important;
        line-height: 27px !important;
        opacity: 1;
        position: absolute !important;
        bottom: 25px;
        left: 50%;
        transform: translate(-50%, 0);
        text-transform: none !important;
        letter-spacing: -0.7px !important;
        @include breakpoint-lg(min) {
            padding-top: 11px !important;
        }
        @include breakpoint-sm0(max) {
            font-size: 16px !important;
        }
        @include breakpoint-sm(max) {
            font-size: 15px !important;
        }
        @include breakpoint-sm(max) {
            font-size: 13px !important;
            bottom: 0px;
        }
        &.btn-reveal i {
            top: 44% !important;
        }

        &.add-name-btn {
            position: initial !important;
            margin: 2px 0;
            left: initial !important;
            transform: initial !important;
            padding-left: 35px !important;
            padding-right: 35px !important;
        }
    }
    .name-field-and-button {
        z-index: 101;
        position: absolute !important;
        bottom: 70px;
        opacity: 0.95;
        display: flex;
        width: 100%;
        justify-content: center;

        @include breakpoint-sm(max) {
            bottom: 0px;
        }

        .name-field-container-0 {
            display: flex;
            background: #fff;
            -webkit-box-shadow: 0px 0px 2px 5px rgba(255, 255, 255, 0.5);
            -moz-box-shadow: 0px 0px 2px 5px rgba(255, 255, 255, 0.5);
            box-shadow: 0px 0px 2px 5px rgba(255, 255, 255, 0.5);
            border-radius: 36px;
            border: 2px solid #a75a9b;
            .title {
                display: flex;
                align-items: center;
                margin-left: 20px;
                cursor: initial;
            }
            .name-field-container {
                z-index: 100;
                margin-right: -40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .name-field {
                    border: none !important;
                    outline: none !important;
                    padding-right: 50px;
                    padding-left: 10px;
                    padding-top: 7px;
                    font-size: 23px;
                    background: transparent;
                    max-width: 260px;
                    text-align: center;
                    @include breakpoint-sm0(max) {
                        font-size: 19px !important;
                        max-width: 230px;
                    }
                    @include breakpoint-sm(max) {
                        font-size: 14px !important;
                        min-height: 30px;
                        max-width: 190px;
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }
            }
            .video-make-btn {
                position: relative !important;
                left: initial !important;
                bottom: initial !important;
                margin-bottom: 0;
                -webkit-box-shadow: none !important;
                -moz-box-shadow: none !important;
                box-shadow: none !important;
                transform: none !important;
                margin-right: -2px;
            }
        }
    }
}
.splide__arrow{
    background: rgb(255 255 255 / 80%);
    width: 50px;
    height: 50px;
    line-height: 40px;
    display: block;
    position: absolute;
    top: 50%;
    cursor: pointer;
    text-align: center;
    transition: all 0.2s ease 0s;
    color: #9896a6;
    z-index: 200;
    box-sizing: initial;
    outline: none;
    border-radius: 50%;
    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1);
    border: 1px solid rgb(123 123 123 / 23%);

    @include breakpoint-sm0(max) {
        width: 45px;
        height: 45px;
    }
    @include breakpoint-sm(max) {
        width: 40px;
        height: 40px;
    }
    @include breakpoint-sm2(max) {
        width: 35px;
        height: 35px;
        line-height: 35px !important;
        top: 45%;
        svg {
            height: 1em !important;
            width: 1em !important;
        }
    }
    @include breakpoint-sm3(max) {
        top: 40%;
    }

    &:focus,
    &:focus-visible{
        outline: none;
    }
    &:hover {
        svg {
            fill: $color-theme !important;
        }
    }
    svg {
        fill: #717171;
        height: 1.2em;
        transition: fill .2s linear;
        width: 1.2em;
        padding-bottom: 2px;
        &:hover {
            fill: $color-theme !important;
        }
    }
}

.splide__pagination {
    bottom: -2em;
    .splide__pagination__page {
        margin: 3px 8px;
        &:hover {
            background: #c583bb;
        }
        &.is-active {
            background: $color-theme;
            outline: none;
            &:hover {
                outline: none;
                background: #c583bb;
            }
        }
    }
}

.container-card-category {
    .homepage-video-slider-section {
        padding-top: 20px !important;
        padding-bottom: 60px !important;
        @include breakpoint-md1(max) {
            margin-left: -30px;
            width: calc(100% + 60px);
        }
        .container {
            max-width: 100% !important;
            padding: 0 !important;
            .splide__slide {
                .video-make-btn,
                .video-make-btn.add-name-btn{
                    @include breakpoint-lg(max) {
                        padding: 8px 20px 10px;
                    }
                    @include breakpoint-sm0(max) {
                        padding: 4px 15px 5px;
                    }
                    @include breakpoint-sm(max) {
                        padding: 0 10px;
                    }
                }
                .video-card-title {
                    font-size: 32px;
                    line-height: 36px;

                    @include breakpoint-md2(max) {
                        font-size: 32px;
                        line-height: 36px;
                    }
                    @include breakpoint-md1(max) {
                        font-size: 28px;
                        line-height: 30px;
                    }
                    @include breakpoint-md(max) {
                        font-size: 24px;
                        line-height: 26px;
                    }
                    @include breakpoint-sm0(max) {
                        font-size: 21px;
                        line-height: 23px;
                    }
                    @include breakpoint-sm(max) {
                        font-size: 19px;
                        line-height: 21px;
                    }
                    @include breakpoint-sm2(max) {
                        font-size: 17px;
                        line-height: 19px;
                    }
                }
            }
        }
    }
}